

.sidebar-collapse {
    .pro-sidebar{
        position: sticky;
        top: 0px !important;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 90vh;
        overflow-y: scroll;
        height: unset !important;
    }

}
.sidebar{
    flex: 1;
    border-right: 0.5px solid rgb(230,227,227);
    min-height: 100vh;
    background-color: white;
    margin-left: 0px;
    

    .top{
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.5px solid rgb(230,227,227);
        position: sticky;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 90vh;
        overflow-y: auto;


    .logo{
        font-size: 20px;
        font-weight: bold;
        color: #42526e;
        display: flex;
        align-items: center;
        flex-direction: row;
        img{
            height: 30px;
            margin-right: 10px;
        }
    }

    }
    .pro-sidebar{
        position: sticky;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 90vh;
        overflow-y: scroll;
        height: unset;
        z-index: 99 !important;
    }

    

        .center{
            padding-left: 10px;

            ul{
                list-style: none;
                margin: 0;
                padding: 0;

                .title{
                    font-size: 10px;
                    font-weight: bold;
                    color: #999;
                    margin-top: 15px;
                    margin-bottom: 5px;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    cursor: pointer;

                    &:hover{
                        background-color: #deebff;
                        span{
                            color: #0052cc;
                        }
                    }

                    .icon{
                        font-size: 22px;
                        color: #42526e;
                    }

                    span{
                        font-size: 15px;
                        font-weight: 500;
                        color: #42526e;
                        margin-left: 10px;
                    }
                }
            }
        }

        .bottom{
            display: flex;
            margin: 10px;
  

            .colorOption{
                width: 20px;
                height: 20px;
                border-radius: 5px;
                border: 1px solid #7451f8;
                cursor: pointer;
                margin: 5px;

                &:nth-child(1){
                    background-color: whitesmoke;
                }
                &:nth-child(2){
                    background-color: #333;
                }
            }
        }
        .pro-sidebar {
            .pro-sidebar-inner{
                background-color: #ffffff;
            }
            .pro-menu {
                .pro-menu-item{
                    font-size: 16px;
                    color: #42526e !important;
                    .pro-inner-item:hover, .pro-inner-item:focus{
                        color: #0052cc !important;
                    }
                    .pro-icon-wrapper{
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                    }
                    
                }
                .active{
                    color: #0052cc !important;
                    background: #c0d9ff;
                }
                ul {
                    .pro-sub-menu {
                        .pro-inner-list-item {
                            position: relative;
                            background-color: #e5dfdf;
                          }
                    }
                }
                
            }
            
        }
        
}
