.call-progess {
  position: relative;

  .custom-progressbar {
    cursor: pointer;
    border-radius: 5px;

    div {
      .progess-text {
        line-height: 2.1;
        border-radius: unset;
        font-size: 13px;
        font-weight: 600 !important;
      }
    }
  }

  .progress-overlay {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    right: 0;
    background-color: white;
    width: 195%;
    z-index: 9999;
    padding: 15px;
    border-radius: 10px;
    transform: translate(-50%, 0);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

    h6 {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      cursor: pointer;

      span {
        float: right;
        font-weight: normal;
        height: 22px;
        background-color: rgba(0, 128, 0, 0.2);
        color: #000;
        display: inline-block;
        min-width: 100%;
        text-align: left;
        border-radius: 4px;
        font-size: 15px;
        padding: 2px 8px;
      }

      .inbound {
        background-color: green;
        color: #fff;
        font-weight: 400;
        cursor: "pointer";
        float: left;
      margin-bottom: 6px;
        // text-decoration: underline;
      }

      .outbound {
        background-color: rgb(218 165 32 / 67%);
        color: #000;
        font-weight: 400;
        float: left;
    margin-bottom: 6px;
        // text-decoration: underline;
      }

      .outbound1 {
        background-color: rgba(145, 233, 233, 0.67);
        color: #000;
        font-weight: 400;
        float: left;
    margin-bottom: 6px;
      }

      .totalbound {
        background-color: gray;
        color: #fff;
        font-weight: 400;
        cursor: "pointer";
        float: left;
    margin-bottom: 6px;

      }
    }

    .progress-bar-hyperlink {
      a {
        text-decoration: none;
        color: #000;
      }

      a:hover {
        color: #0052cc;
        text-decoration: underline;
      }
    }

    .progress-bar-hyperlink:hover {
      color: #0052cc;
      text-decoration: none;

    }

  }
}

.call-progess:hover .progress-overlay {
  display: block;
}