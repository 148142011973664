.featured {
    // flex: 2;
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    // // padding: 10px;


    .top {
        // // padding: 20px;
        // margin-top: 10px;
        // border: 1px solid #c9c9c9;
        // border-radius: 4px;
        // // background-color: #0052cc;
        // color: #fff;

        p{
            margin-bottom: 20px;
        }
        .attendence-col{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content:space-between;
            .attendence-col-1{
                p{
                    margin-bottom: 5px;
                    font-size: 10px !important;
                }
                h4{
                    font-size: 12px;
                    letter-spacing: 2;
                    line-height: 7px !important;
                    span{
                        font-size: 16px;
                    }
                }
            }
            .attendence-col-2{
                display: flex;
                .btn-clock-in{
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 14px;
                    background-color: #0052cc;
                    font-weight: 400;
                    border: 1px solid #0052cc;
                    padding: 6px 6px;
                    border-radius: 4px;
                    cursor: pointer;
                    font-weight: bold;
                    text-transform: capitalize;
                    margin-left: 2px;
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 12px;
                    }
                    
                }
                .btn-clock-out{
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 12px;
                    background-color: #c80808;
                    font-weight: 400;
                    border: 1px solid #c80808;
                    padding: 6px 6px;
                    border-radius: 4px;
                    cursor: pointer;
                    font-weight: bold;
                    text-transform: capitalize;
                    margin-left: 2px;
                    display: flex;
                    align-items: center;
                    
                }
                .btn-clock-break{
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 14px;
                    background-color: #685a48d6;
                    font-weight: 400;
                    border: 1px solid #685a48d6;
                    padding: 6px 6px;
                    border-radius: 4px;
                    cursor: pointer;
                    font-weight: bold;
                    text-transform: capitalize;
                    margin-left: 2px;
                    display: flex;
                    align-items: center;
                    
                }
                .total-time{
                    display: block;
                    margin-top: 8px;
                    margin-left: 25px;
                    font-weight: 600;
                    font-size: 16px;
                }
                h4{
                    font-size: 12px;
                    letter-spacing: 2;
                    line-height: 7px !important;
                    span{
                        font-size: 16px;
                    }
                }
            }
        }
    }

    // .title {
    //     font-size: 16px;
    //     color: #42526e;
    //     font-weight: bold;
    // }

    // .bottom {
    //     padding: 20px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     gap: 15px;

    //     .featuredChart {
    //         width: 100px;
    //         height: 100px;
    //     }

    //     .title {
    //         font-weight: 500;
    //         color: #42526e;
    //     }

    //     .amount {
    //         font-size: 30px;
    //         color: #42526e;
    //         font-weight: bold;
    //     }

    //     .desc {
    //         font-weight: 300;
    //         font-size: 12px;
    //         color: #42526e;
    //         text-align: center;
    //     }

    //     .summary {
    //         width: 100%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;

    //         .item {
    //             text-align: center;

    //             .itemTitle {
    //                 font-size: 14px;
    //                 color: #42526e;
    //             }

    //             .itemResult {
    //                 display: flex;
    //                 align-items: center;
    //                 margin-top: 10px;
    //                 font-size: 14px;

    //                 &.positive {
    //                     color: green;
    //                 }

    //                 &.negative {
    //                     color: red;
    //                 }
    //             }
    //         }
    //     }
    // }
}